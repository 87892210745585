.welcome-dunedin-modal-content {
    background: rgba(42, 229, 81, 0.15);
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.15);
    width: 500px;
    max-width: 100%;
    min-width: 275px;
    height: auto;
    z-index: 0;

    p {
        margin-top: 8px;
        margin-bottom: 8px;

        @media (max-height:400px) {
            & {
                margin-top: 2px;
                margin-bottom: 2px;
            }
        }
    }

    button {
        font-size: 18px;
    }

    .modal-top-image-wrapper {
        background-image: linear-gradient(0deg, rgba(75, 79, 105, 0.31) 0%, rgba(216, 216, 216, 0.00) 100%), url('../../../images/dunedin-welcome-cover-image.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: relative;
        width: 100%;
        min-height: 100px;
        height: 200px;
        max-height: 20vh;
        box-shadow: 0px 2px 8px 0px #00000099;

        @media (max-height:600px) {
            & {
                max-height: 100px;
            }
        }

        @media (max-height:400px) {
            & {
                display: none;
            }
        }
    }

    .mm-logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;

        img {
            &:first-child{
                height: 40px;
            }
            height: 18px;
        }

        @media (max-width:600px) {
            & {
                height: 20px;
                min-width: 50px;
                min-height: 50px;
            }

            img {
                &:first-child{
                    height: 30px;
                }
                height: 16px;
            }
        }

        @media (max-width:400px) {
            & {
                height: 30px;
                min-width: 25px;
                min-height: 25px;
                margin-bottom: 5px;
            }

            img {
                &:first-child{
                    height: 30px;
                }
                height: 16px;
            }
        }
    }

    .mazemap-logo-text {
        margin-top: -30px;
        width: 175px;

        @media (max-height:600px) {
            & {
                margin-top: -15px;
                width: 135px;
            }
        }

        @media (max-height:400px) {
            & {
                display: none;
            }
        }
    }

    .dunedin-modal-desc {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        text-align: start;
        color: #434343;
        margin-inline-start: 32px;
        margin-inline-end: 32px;

        .wifi-container{
            display: flex;
            flex-direction: row;
            margin-block: 10px;
            .wifi-key {
                font-weight: 700;
                margin-inline-end: 10px;
            }
        }

    }


    .user-start-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        margin-top: 50px;
        color: #222;
        text-align: start;
        margin-inline-start: 32px;
        margin-inline-end: 32px;

        @media (max-width:400px) {
            & {
                margin-top: 10px;
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }

    }

    .or-word-seperator {
        font-style: italic;
        font-size: 14px;
        color: #434343;
    }

    .skip-step-button {
        margin-top: 10px;
        text-decoration: underline;
        font-weight: normal;
        margin-bottom: 10px;

        @media (max-height:400px) {
            & {
                margin-top: 0px;
            }
        }
    }

    .app-link-styling{
        text-decoration: underline;
        color: #222;
    }
}
